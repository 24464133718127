@import "../../styles/_chunk";

.homepage-trust-banner {
  background-color: map-get($dark-theme, secondary-background-color);
  margin: 2rem 0;
  padding: 1rem 0;

  @include theme(light) {
    background-color: map-get($light-theme, secondary-background-color);
  }

  @include media-breakpoint-down(sm) {
    display: none;
  }

  a {
    color: map-get($dark-theme, font-color);

    @include theme(light) {
      color: map-get($light-theme, font-color);
    }
  }

  .banner-point-heading {
    font-size: 1.5rem;
    line-height: 2.75rem;
    font-weight: 700;

    @include media-breakpoint-up(xl) {
      font-size: 1.75rem;
    }
  }

  .banner-point-text {
    font-size: 1.125rem;

    @include media-breakpoint-up(xl) {
      font-size: 1.375rem;
      line-height: 2rem;
    }
  }

  .banner-point {
    padding: 1rem 0;
    text-align: center;

    &.top-point {
      padding-bottom: 0.75rem;
    }

    &.bottom-point {
      padding-top: 0.75rem;
    }
  }

  .banner-content-container {
    display: flex;

    .left-column {
      width: 30%;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;

      @include media-breakpoint-up(lg) {
        width: 25%;
      }
    }

    .middle-column {
      width: 40%;
      display: flex;
      align-items: center;
      justify-content: center;

      @include media-breakpoint-up(lg) {
        width: 50%;
      }

      .trustpilot-point {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 1rem 0;

        .trustpilot-image-container {
          width: 250px;
          height: 30px;
          margin-bottom: 0.5rem;

          @include media-breakpoint-up(lg) {
            width: 360px;
            height: 42px;
          }

          @include media-breakpoint-up(xl) {
            width: 400px;
            height: 47px;
          }

          @include media-breakpoint-up(xxl) {
            width: 420px;
            height: 49px;
          }
        }

        .trustpilot-vertical-image-container {
          width: 180px;
          height: 90px;
          margin-bottom: 0.5rem;
        }
      }
    }

    .right-column {
      width: 30%;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;

      @include media-breakpoint-up(lg) {
        width: 25%;
      }
    }
  }
}
