@import "../../../styles/_chunk";

.GiveawayBanner {
  background: $black;
  color: $white;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: $border-radius;
  overflow: hidden;
  position: relative;

  @include media-breakpoint-up(md) {
    min-height: 170px;
    flex-direction: row;
  }

  @include media-breakpoint-up(lg) {
    min-height: 190px;
  }

  @include media-breakpoint-up(xl) {
    min-height: 210px;
  }

  @include media-breakpoint-up(xxl) {
    min-height: 220px;
  }

  .GiveawayBanner__contentContainer {
    z-index: 1;
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 1.5rem 1rem;

    @include media-breakpoint-up(md) {
      flex-direction: row;
    }

    @include media-breakpoint-up(lg) {
      padding: 1rem;
    }

    .GiveawayBanner__textLogoContainer {
      width: 100%;
      display: flex;
      flex-direction: column-reverse;
      margin-bottom: 1rem;

      @include media-breakpoint-up(md) {
        width: 65%;
        margin-bottom: 0;
        flex-direction: row;
      }

      @include media-breakpoint-up(lg) {
        width: 70%;
        margin-bottom: 0;
      }

      .GiveawayBanner__textContainer {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;

        @include media-breakpoint-up(md) {
          padding: 0 0.75rem;
        }

        @include media-breakpoint-up(lg) {
          width: 40%;
        }

        @include media-breakpoint-up(xl) {
          width: 45%;
          padding: 0 1rem;
        }

        @include media-breakpoint-up(xxl) {
          padding: 0 2rem;
        }

        .GiveawayBanner__text {
          text-align: center;
          text-transform: uppercase;
          font-weight: 700;
          font-size: 15px;

          @include media-breakpoint-up(sm) {
            font-size: 16px;
          }

          @include media-breakpoint-up(md) {
            font-size: 15px;
          }

          @include media-breakpoint-up(lg) {
            font-size: 18px;
          }

          @include media-breakpoint-up(xl) {
            font-size: 22px;
          }

          @include media-breakpoint-up(xxl) {
            font-size: 24px;
          }
        }
      }

      .GiveawayBanner__logoContainer {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 1rem;

        @include media-breakpoint-up(md) {
          width: 60%;
          margin-bottom: 0;
        }

        @include media-breakpoint-up(xl) {
          width: 55%;
        }

        .GiveawayBanner__logo {
          width: 240px;
          height: 100px;

          @include media-breakpoint-up(md) {
            width: 200px;
            height: 83px;
          }

          @include media-breakpoint-up(lg) {
            width: 260px;
            height: 108px;
          }

          @include media-breakpoint-up(xl) {
            width: 300px;
            height: 125px;
          }

          @include media-breakpoint-up(xxl) {
            width: 320px;
            height: 133px;
          }

          img {
            height: 100%;
            width: 100%;
            object-fit: contain;
          }
        }
      }
    }

    .GiveawayBanner__priceButtonContainer {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;

      @include media-breakpoint-only(sm) {
        flex-direction: row;
      }

      @include media-breakpoint-up(md) {
        width: 35%;
      }

      @include media-breakpoint-up(lg) {
        width: 30%;
      }

      .GiveawayBanner__priceContainer {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 1rem;
        width: 100%;

        @include media-breakpoint-only(sm) {
          margin-bottom: 0;
          width: 50%;
        }
      }

      .GiveawayBanner__discount {
        background-color: $grey-12;
        margin-right: 0.75rem;
        font-weight: 700;
        font-size: 20px;
        line-height: 20px;
        color: $white;
        padding: 1rem 0.5rem;
        border-radius: $border-radius;

        @include media-breakpoint-up(lg) {
          font-size: 24px;
          line-height: 24px;
        }

        @include media-breakpoint-up(xl) {
          font-size: 26px;
          line-height: 26px;
        }

        @include media-breakpoint-up(xxl) {
          font-size: 28px;
          line-height: 28px;
          padding: 1.25rem 0.75rem;
          margin-right: 1rem;
        }
      }

      .GiveawayBanner__price {
        @include brand-property(color, brand-core);

        font-weight: 900;
        font-size: 24px;
        line-height: 24px;

        @include media-breakpoint-up(md) {
          font-size: 26px;
          line-height: 26px;
        }

        @include media-breakpoint-up(lg) {
          font-size: 32px;
          line-height: 32px;
        }

        @include media-breakpoint-up(xl) {
          font-size: 34px;
          line-height: 34px;
        }

        @include media-breakpoint-up(xxl) {
          font-size: 36px;
          line-height: 36px;
        }
      }

      .GiveawayBanner__wasPrice {
        color: $text-muted;
        text-decoration: line-through;
        font-weight: 700;
        font-size: 20px;
        line-height: 20px;
        margin-bottom: 0.125rem;
        text-align: right;

        @include media-breakpoint-up(md) {
          font-size: 22px;
          line-height: 22px;
        }

        @include media-breakpoint-up(lg) {
          font-size: 26px;
          line-height: 26px;
        }

        @include media-breakpoint-up(xl) {
          font-size: 28px;
          line-height: 28px;
        }

        @include media-breakpoint-up(xxl) {
          font-size: 30px;
          line-height: 30px;
        }
      }

      .GiveawayBanner__ctaButton {
        border: 1px solid $white;
        padding: 0.5rem 1.25rem;
        text-transform: uppercase;
        border-radius: $border-radius-sm;
        font-weight: 700;
        font-size: 15px;
        text-align: center;

        @include media-breakpoint-up(lg) {
          font-size: 16px;
          padding: 0.5rem 1.5rem;
        }

        @include media-breakpoint-up(xl) {
          font-size: 18px;
        }

        @include media-breakpoint-up(xxl) {
          font-size: 20px;
          padding: 0.5rem 1.75rem;
        }
      }
    }
  }

  .GiveawayBanner__background {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      filter: blur(3px);
      opacity: 0.45;
      transform: scale(1.03);
    }
  }
}
